<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="border-bottom border-3 border-yellow-500">
      <CImage :src="`${publicPath}spmsicon.svg`" height="50"/> <span class="p-3 fs-2 fw-bolder text-yellow-500">SPMS</span>
    </CSidebarBrand>
    <AppSidebarNav />

  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  data: () => {
    return {
      publicPath: process.env.BASE_URL,
      visiblePrivacyAgreement: false
    };
  },
  setup() {
    const store = useStore()
    return {
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
