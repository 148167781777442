import Swal from 'sweetalert2/dist/sweetalert2';
const global_functions = {
    frmdata(obj) {
        var formData = new FormData();
        for (var key in obj) {
            let val = (typeof (obj[key]) === 'object') ? JSON.stringify(obj[key]) : obj[key];
            formData.append(key, val);
        }
        return formData;
    },
    show_response_swal(title, message, icon, timer = 1500) {
        let timerInterval
        Swal.fire({
            icon: icon,
            title: title,
            html: message,
            timer: timer,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                // console.log('I was closed by the timer')
            }
        })
    },
    async swal_yes_no(title = 'Are you sure?', message = '', icon = 'warning') {
        let p = false;
        await Swal.fire({
            title: title,
            text: message,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                p = true;
            }
        })
        return p;
    }
};

export default global_functions;