import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
  },
  
  {
    path: '/changepw',
    name:'ChangePW',
    component: () =>
      import(/* webpackChunkName: "pages" */ '@/views/ChangePW.vue'),
  },
  {
    path: '/',
    name: 'Start',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name:'Home',
        component: () =>
          import(/* webpackChunkName: "pages" */ '@/views/Home.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/management',
        name: 'Item Management',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/management',
        children: [
          {
            path: '/management/item',
            name: 'Item Names List',
            component: () => import('@/views/management/NameList'),
          },
          {
            path: '/management/item/view',
            name: 'Item View',
            component: () => import('@/views/management/NameView'),
          },
          {
            path: '/management/variant',
            name: 'Item Variant List',
            component: () => import('@/views/management/VariantList'),
          },
          {
            path: '/management/category',
            name: 'Item Category List',
            component: () => import('@/views/management/CategoryList'),
          },
        ],
      },
      {
        path: '/transaction/purchase',
        name: 'Purchase',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/transaction/purchase/list',
        children: [
          {
            path: '/transaction/purchase/list',
            name: 'Purchase List',
            component: () => import('@/views/purchase/List'),
          },
          {
            path: '/transaction/purchase/submit',
            name: 'Purchase Submit',
            component: () => import('@/views/purchase/Submit'),
          },
          {
            path: '/transaction/purchase/:id',
            name: 'Purchase View',
            component: () => import('@/views/purchase/View'),
          },
          {
            path: '/transaction/purchase/update/:id',
            name: 'Purchase Update',
            component: () => import('@/views/purchase/Update'),
          },
        ],
      },
      {
        path: '/transaction/request',
        name: 'Request',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/transaction/request/list',
        children: [
          {
            path: '/transaction/request/list',
            name: 'Request List',
            component: () => import('@/views/request/List'),
          },
          {
            path: '/transaction/request/submit',
            name: 'Request Submit',
            component: () => import('@/views/request/Submit'),
          },
          {
            path: '/transaction/request/:id',
            name: 'Request View',
            component: () => import('@/views/request/View'),
          },
          {
            path: '/transaction/request/update/:id',
            name: 'Request Update',
            component: () => import('@/views/request/Update'),
          },
        ],
      },
      {
        path: '/transaction/issuance',
        name: 'Issuance',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/transaction/issuance/list',
        children: [
          {
            path: '/transaction/issuance/list',
            name: 'Issuance List',
            component: () => import('@/views/issuance/List'),
          },
          {
            path: '/transaction/issuance/submit',
            name: 'Issuance Submit',
            component: () => import('@/views/issuance/Submit'),
          },
          {
            path: '/transaction/issuance/ris',
            name: 'RIS',
            component: () => import('@/views/issuance/FormRIS'),
          },
          {
            path: '/transaction/issuance/ics',
            name: 'ICS',
            component: () => import('@/views/issuance/FormICS'),
          },
          {
            path: '/transaction/issuance/par',
            name: 'PAR',
            component: () => import('@/views/issuance/FormPAR'),
          },
          {
            path: '/transaction/issuance/:id',
            name: 'Issuance View',
            component: () => import('@/views/issuance/View'),
          },
          {
            path: '/transaction/issuance/update/:id',
            name: 'Issuance Update',
            component: () => import('@/views/issuance/Update'),
          },
        ],
      },
      {
        path: '/employee/list',
        name: 'Employee List',
        component: () => import('@/views/employee/List.vue'),
      },
      {
        path: '/employee/view',
        name: 'Employee View',
        component: () => import('@/views/employee/View.vue'),
      },
      {
        path: '/office/list',
        name: 'Office View',
        component: () => import('@/views/office/OfficeList.vue'),
      },
    ],
  },
  {
    path: '/admin_nav/users',
    component: DefaultLayout,
    redirect: '/admin_nav/users',
    children: [
      {
        path: '/admin_nav/users',
        name: 'Users',
        component: () => import('@/views/admin_nav/users/Users'),
      },
      {
        path: '/admin_nav/add_users',
        name: 'Add User',
        component: () => import('@/views/admin_nav/users/Add'),
      },
    ]
  },

  { path: '/:pathMatch(.*)*', component: () => import('@/views/pages/Page404') },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
