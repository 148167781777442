
import { 
    faDownload,
    faUser,
    faUsers,
    faUserPlus,
    faUserGroup,
    faPlus,
    faCirclePlus,
    faSquarePlus,
    faTrash,
    faEdit,
    faFile,
    faFileLines,
    faPaste,
    faBook,
    faBookOpen,
    faFolder,
    faFolderOpen,
    faTag,
    faTags,
    faMap,
    faLocationDot,
    faMoneyBill,
    faMoneyBillWave,
    faCalendar,
    faCalendarDays,
    faCircle,
    faCircleHalfStroke,
    faPaperclip,
    faMicroscope,
    faStar,
    faHeart,
    faDiamond,
    faDeleteLeft,
    faInfo,
    faEye,
    faCircleCheck,
    faSliders,
    faFilePdf,
    faFileCsv,
    faFileExcel,
    faFileExport,
    faFileCirclePlus,
    faList,
    faSeedling,
    faFishFins,
    faEarthAsia,
    faFlaskVial,
    faCommentsDollar,
    faGraduationCap,
    faPlateWheat,
    faTree,
    faVenusMars,
    faHeartPulse,
    faFeather,
    faDiagramProject,
    faLightbulb,
    faCalculator,
    faMountainSun,
    faLeaf,
    faLandmark,
    faUsersBetweenLines,
    faMagnifyingGlass,
    faHandshake,
    faNewspaper,
    faCopyright,
    faTriangleExclamation,
    faShieldHalved,
    faCircleUser,
    faLink,
    faBell,
    faMobile,
    faFaceFrown,
    faFaceSmile,
    faFaceLaughBeam,
    faFaceFrownOpen,
    faFaceMeh,
    faScrewdriverWrench,
    faCompactDisc,
    faWifi,
    faGlobe,
    faFileInvoice,
    faComments,
    faBan,
    faPowerOff,
    faToggleOn,
    faToggleOff,
    faRotateRight,
    faUnlockKeyhole,
    faCheck,
    faClock,
    faMinus,
    faEllipsis,
    faXmark,
    faCircleXmark,
    faClipboardCheck,
    faFileCircleCheck,
    faMagnifyingGlassArrowRight,
    faPhone,
    faCertificate,
    faTicket,
    faUserGear,
    faWrench,
    faCircleNotch,
    faTableCells,
    faTableCellsLarge,
    faHashtag,
    faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'

import { 
    faCircle as farCircle,
} from '@fortawesome/free-regular-svg-icons'

export const iconsFASet = Object.assign(
{
    faDownload,
    faUser,
    faUsers,
    faUserPlus,
    faUserGroup,
    faPlus,
    faCirclePlus,
    faSquarePlus,
    faTrash,
    faEdit,
    faFile,
    faFileLines,
    faPaste,
    faBook,
    faBookOpen,
    faFolder,
    faFolderOpen,
    faTag,
    faTags,
    faMap,
    faLocationDot,
    faMoneyBill,
    faMoneyBillWave,
    faCalendar,
    faCalendarDays,
    faCircle,
    farCircle,
    faCircleHalfStroke,
    faPaperclip,
    faMicroscope,
    faStar,
    faHeart,
    faDiamond,
    faDeleteLeft,
    faInfo,
    faEye,
    faCircleCheck,
    faSliders,
    faFilePdf,
    faFileCsv,
    faFileExcel,
    faFileExport,
    faFileCirclePlus,
    faList,
    faSeedling,
    faFishFins,
    faEarthAsia,
    faFlaskVial,
    faCommentsDollar,
    faGraduationCap,
    faPlateWheat,
    faTree,
    faVenusMars,
    faHeartPulse,
    faFeather,
    faDiagramProject,
    faLightbulb,
    faCalculator,
    faMountainSun,
    faLeaf,
    faLandmark,
    faUsersBetweenLines,
    faMagnifyingGlass,
    faHandshake,
    faNewspaper,
    faCopyright,
    faTriangleExclamation,
    faShieldHalved,
    faCircleUser,
    faLink,
    faBell,
    faMobile,
    faFaceFrown,
    faFaceSmile,
    faFaceLaughBeam,
    faFaceFrownOpen,
    faFaceMeh,
    faScrewdriverWrench,
    faCompactDisc,
    faWifi,
    faGlobe,
    faFileInvoice,
    faComments,
    faBan,
    faPowerOff,
    faToggleOn,
    faToggleOff,
    faRotateRight,
    faUnlockKeyhole,
    faCheck,
    faClock,
    faMinus,
    faEllipsis,
    faXmark,
    faCircleXmark,
    faClipboardCheck,
    faFileCircleCheck,
    faMagnifyingGlassArrowRight,
    faPhone,
    faCertificate,
    faTicket,
    faUserGear,
    faWrench,
    faCircleNotch,
    faTableCells,
    faTableCellsLarge,
    faHashtag,
    faArrowUpRightFromSquare,
},
)
  