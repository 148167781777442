import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router/router'
import { useAuthStore } from '@/stores/auth.js'
import stores from './stores'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import { iconsFASet as iconsFA } from '@/assets/icons/fontawesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/styles/style.scss';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const auth = useAuthStore(pinia);

router.afterEach(to => {
    document.title = to.meta?.title
        ? `${to.meta.title} — ${auth.sys_title}`
        : auth.sys_title
})

const app = createApp(App)

router.beforeEach(async (to) => {
    const publicPages = ['/'];
    const authRequired = !publicPages.includes(to.path);
    if (authRequired && (!auth.user.token || !auth.user.user_id) && !auth.user.isLoggedIn) {
        auth.returnUrl = to.fullPath;
        return '/';
    } else {
        if (to.path != '/') {
            try {
                var p = JSON.parse(auth.user.a_p);
                if (!p_contains(to.path, p)) {
                    return p[0]; //CHANGE TO DEFAULT ERROR PAGE
                }
            } catch (error) {
                // console.log(error);
            }
        }
    }
    function p_contains(path, pages) {
        for (let key in pages) {
            if (path == pages[key]) {
                return true;
            }
        }
        return false;
    }
});

app.use(stores)
app.use(router)
app.use(pinia)
app.use(CoreuiVue)
app.use(VueSweetalert2)
app.provide('icons', icons)
app.component('CIcon', CIcon)

library.add(iconsFA)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)
app.component('font-awesome-layers-text', FontAwesomeLayersText)
app.mount('#app')


