<template>
  <CFooter>
    <div>
      <!-- <a href="https://eticket.bsu.edu.ph" target="_blank">BSU</a> -->
      <!-- <span class="ms-1"
        >&copy; 2022</span
      > -->
    </div>
    <!-- <div class="ms-auto">
      Powered by COREUI
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
