<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <span class="text-green-600 text-uppercase">{{ fullname }}</span>&nbsp;&nbsp;&nbsp;<CAvatar :src="`${publicPath}img/bsulogo.png`" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <!-- <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader> -->
      <!-- <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader> -->
      <!-- <CDropdownItem href="/"> <CIcon icon="cil-user" /> Profile </CDropdownItem> -->
      <!-- <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem> -->
      <!-- <CDropdownDivider /> -->
      <CDropdownItem href="#" @click=authStore.logout()> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useAuthStore } from '../stores/auth'
  
  const publicPath = process.env.BASE_URL
  const authStore = useAuthStore()
  const fullname = computed(() => authStore.user.fullname)
  </script>
