import axios from "axios";
import { useAuthStore } from './stores/auth'
import router from "./router/router";

const axiosClient = axios.create({
  baseURL: 'http://localhost:8084/',
  withCredentials: true,
  timeout: 10000,
})

axiosClient.interceptors.request.use(config => {
  const ses = useAuthStore();
  config.headers = { 'Authorization': 'Bearer ' + ses.user.token };

  return config;
})

axiosClient.interceptors.response.use(response => {
  return response;
}, error => {
  const auth = useAuthStore();
  if (error.response.status === 403) {
    router.push({ name: 'Login' });
    // auth.logout();
  } else if (error.response.status === 401) {
    auth.logout();
    router.push({ name: 'Login' });
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message);
  }
  return
})

export default axiosClient;




// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   if(response.data.ustatus == 0) {
//       localStorage.removeItem('user');
//       router.push('/login');
//   }
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   // return Promise.reject(error);
//   let errorStatus = [401]
//   if(errorStatus.includes(error.response.status)) {
//       localStorage.removeItem('user');
//       router.push('/login');
//   }
// });
