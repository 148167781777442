import { defineStore } from "pinia";
import axiosClient from "../axios";
import router from "../router/router";
// import SecureLS from "secure-ls";
import global_scripts from "../global_script";
// const ls = new SecureLS({ encodingType: 'aes' });
export const useAuthStore = defineStore("sessionStore", {
    state: () => {
        return {
            user: {
                user_id: localStorage.getItem("user_id"),
                token: localStorage.getItem("token"),
                fullname: localStorage.getItem("fullname"),
                isLoggedIn: localStorage.getItem("isLoggedIn"),
                emp_id: localStorage.getItem("emp_id"),
                emp_office: localStorage.getItem("emp_office"),
                a_p: localStorage.getItem("a_p"),
                default_pw: localStorage.getItem("default_pw"),
            },
            sys_title: 'Supply and Property Management System',
            ict_office: localStorage.getItem("ict_office"), 
        };
    },
    getters: {

    },
    actions: {
        async checkLogin(user) {
            try {
                const self = this;
                await axiosClient.post("/check-login", global_scripts.frmdata(user)).then(({ data }) => {
                    if (data.response.success) {
                        self.user.user_id = data.response.user_id;
                        self.user.token = data.response.token;
                        self.user.fullname = data.response.fullname;
                        self.user.isLoggedIn = data.response.success;
                        self.user.emp_id = data.response.emp_id;
                        self.user.emp_office = data.response.emp_office;
                        self.user.a_p = data.response.a_p;
                        self.user.default_pw = data.response.default_pw;
                        localStorage.setItem("user_id", self.user.user_id);
                        localStorage.setItem("token", self.user.token);
                        localStorage.setItem("fullname", self.user.fullname);
                        localStorage.setItem("isLoggedIn", self.user.isLoggedIn);
                        localStorage.setItem("emp_id", self.user.emp_id);
                        localStorage.setItem("emp_office", self.user.emp_office);
                        localStorage.setItem("a_p", self.user.a_p);
                        localStorage.setItem("default_pw", self.user.a_p);
                        self.ict_office = 43;
                        localStorage.setItem("ict_office", self.ict_office);
                        if (data.response.default_pw) {
                            router.push({ name: 'ChangePW' })
                        } else {
                            // router.push({ name: 'Dashboard' })
                            location.href = 'dashboard';
                        }
                    } else {
                        global_scripts.show_response_swal(data.response.alert, data.response.message, data.response.alert);
                    }
                    return data;
                });
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async logout() {
            try {
                const self = this;
                await axiosClient.post("/logout").then(({ data }) => {
                    if (data.success) {
                        self.user.user_id = 0;
                        self.user.token = '';
                        self.user.fullname = "";
                        self.user.position = "";
                        self.user.profile_pic = "";
                        self.user.emp_status = "";
                        self.user.isLoggedIn = false;
                        self.user.a_p = "";
                        self.user.default_pw = "";
                        localStorage.removeItem("token");
                        localStorage.removeItem("user_id");
                        localStorage.removeItem("fullname");
                        localStorage.removeItem("isLoggedIn");
                        localStorage.removeItem("emp_id");
                        localStorage.removeItem("emp_office");
                        localStorage.removeItem("a_p");
                        localStorage.removeItem("default_pw");
                        localStorage.removeItem("ict_office");
                        localStorage.removeAll();
                        router.push({ name: 'Login' })

                    }

                    return data;
                });
            } catch (error) {
                console.log(error);
                return error;
            }

        },
    },
});


