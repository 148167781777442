<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer class="" fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <!-- <CImage height="42" :src="`${publicPath}rplogo.svg`"/> -->
      </CHeaderBrand>
      <CHeaderNav>
        <CNavItem>
        </CNavItem>
        <!-- <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem> -->
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer class="custom-header-breadcrumbs" fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
export default {
  name: 'AppHeader',
  data: () => {
      return {
          publicPath: process.env.BASE_URL,
          visiblePrivacyAgreement: false,
      }
  },
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
}
</script>