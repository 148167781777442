export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    
  },
  {
    component: 'CNavGroup',
    name: 'Transactions',
    to: '/transaction/purchase/list',
    icon: 'cil-file',
    items: [
      {
        component: 'CNavItem',
        name: 'Purchases',
        to: '/transaction/purchase/list',
        icon: 'cil-file',
      },
      {
        component: 'CNavItem',
        name: 'Requests',
        to: '/transaction/request/list',
        icon: 'cil-file',
        
      },
      {
        component: 'CNavItem',
        name: 'Issuances',
        to: '/transaction/issuance/list',
        icon: 'cil-file',
        
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Stocks Management',
    to: '/expendable',
    icon: 'cil-grid',
    items: [
      {
        component: 'CNavItem',
        name: 'Expendable Supplies',
        to: '/expendable',
        icon: 'cil-grid',
        
      },
      {
        component: 'CNavItem',
        name: 'Semi-Expendables',
        to: '/semiexpendable',
        icon: 'cil-grid',
        
      },
      {
        component: 'CNavItem',
        name: 'PPE',
        to: '/equipment',
        icon: 'cil-grid',
        
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Item Management',
    icon: 'cil-spreadsheet',
    to: '/management/item',
    items: [
      {
        component: 'CNavItem',
        name: 'Item Names',
        to: '/management/item',
        icon: 'cil-spreadsheet',
        
      },
      {
        component: 'CNavItem',
        name: 'Item Variants',
        to: '/management/variant',
        icon: 'cil-spreadsheet',
        
      },
      {
        component: 'CNavItem',
        name: 'Item Category',
        to: '/management/category',
        icon: 'cil-spreadsheet',
        
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Employees and Office',
    to: '/employee/list',
    icon: 'cil-user',
    items: [
      {
        component: 'CNavItem',
        name: 'Employee List',
        to: '/employee/list',
        icon: 'cil-user',
        
      },
      {
        component: 'CNavItem',
        name: 'Office List',
        to: '/office/list',
        icon: 'cil-user',
        
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Reports and Registries',
    to: '/report/list',
    icon: 'cil-file',
  },
  {
    component: 'CNavGroup',
    name: 'Administrator',
    icon: 'cil-lock-locked',
    to: '/admin_nav/users',
    items: [
      {
        component: 'CNavItem',
        name: 'Users',
        to: '/admin_nav/users',
      },
    ],
  },
]
